import "./App.css";

import React from "react";
import { ZoomMtg } from "@zoomus/websdk";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

ZoomMtg.setZoomJSLib("https://source.zoom.us/2.4.0/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");

const App = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  let signatureEndpoint = "http://localhost:4000";
  let sdkKey = process.env.REACT_APP_SDK_KEY;
  let meetingNumber = searchParams.get("meeting_id");
  let role = 0;
  let leaveUrl = process.env.REACT_APP_LEAVE_URL;
  let userName = searchParams.get("user_name");
  let userEmail = "";
  let passWord = searchParams.get("meeting_password");
  let registrantToken = "";
  useEffect(() => {
    startMeeting();
  }, []);

  function startMeeting() {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success);

        ZoomMtg.join({
          signature: searchParams.get("signature"),
          meetingNumber: meetingNumber,
          userName: userName,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  return (
    <div className="App">
      <main></main>
    </div>
  );
};

export default App;
